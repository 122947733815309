import React, { useState, useEffect, useContext } from 'react'
import EventEmitter from 'mitt'

import * as cognito from '../services/cognito'
import httpV2 from './../config/httpsV2'
import jwt from 'jwt-decode'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'src/hooks/useLocalStorage'
import { allPartnerFields, allBranchFields } from 'src/views/pages/backoffice/lib/backoffice-lib'
import { MATERIALSTATE } from 'src/views/pages/projectmaterialview/components/lib/projectmaterialview/material-lib'
import { BULK_ACTIONS } from 'src/views/pages/projectmaterialview/components/lib/projectmaterialview/filterSection-lib'

export const ROLE = {
  BUILDING_CONTRACTOR_ADMIN: 'building_contractor_admin',
  BUYER: 'buyer',
  CUBOTOO_ADMIN: 'cubotoo_admin',
  FOREMAN: 'foreman',
  LEAD_BUYER: 'lead_buyer',
  MANUFACTURER: 'manufacturer',
  MANUFACTURER_ADMIN: 'manufacturer_admin',
}

export const VIEWS = {
  PARTNERS: 'partners',
  BRANCHES: 'branches',
  PROJECTS: 'projects',
  USERS: 'users',
  PRODUCTS: 'products',
  MF_ERP_INTEGRATION: 'mfErpIntegration',
  CALENDAR: 'calendar',
}

export const TABS = {
  NEW_ITEMS: '#new-items',
  IN_NEGOTIATOIN: '#in-negotiation',
  CREDITS: '#credits',
}

export const PERMISSIONS = {
  [ROLE.CUBOTOO_ADMIN]: {
    [VIEWS.PARTNERS]: {
      ADD: [],
      EDIT: [...allPartnerFields],
      VIEW: [...allPartnerFields],
      DELETE: [],
      DEACTIVATE: [],
    },
    [VIEWS.BRANCHES]: {
      ADD: [],
      EDIT: [...allBranchFields],
      VIEW: [...allBranchFields],
      DELETE: [],
      DEACTIVATE: [],
    },
    [VIEWS.PROJECTS]: { ADD: [], EDIT: [], DEACTIVATE: [], DELETE: [] },
    [VIEWS.USERS]: { ADD: [], EDIT: [], DEACTIVATE: [], DELETE: [] },
    [VIEWS.PRODUCTS]: { ADD: [], EDIT: [], DEACTIVATE: [], DELETE: [] },
    [VIEWS.MF_ERP_INTEGRATION]: { ADD: [], EDIT: [], DEACTIVATE: [], DELETE: [] },
    [VIEWS.CALENDAR]: { ADD: [], EDIT: [], DEACTIVATE: [], DELETE: [] },
  },
  [ROLE.BUILDING_CONTRACTOR_ADMIN]: {
    [VIEWS.PARTNERS]: {
      EDIT: [
        'addressCountry',
        'street',
        'houseNumber',
        'city',
        'postalCode',
        'contactEmail',
        'invoice_email',
        'credit_email',
      ],
      VIEW: [
        'partner',
        'name',
        'country',
        'vatNum',
        'domain',
        'addressCountry',
        'street',
        'houseNumber',
        'city',
        'postalCode',
        'contactEmail',
        'invoice_email',
        'credit_email',
      ],
    },
    [VIEWS.BRANCHES]: {
      EDIT: [
        'name',
        'primaryContactEmail',
        'street',
        'houseNumber',
        'postalCode',
        'city',
        'email',
        'invoice_email',
        'credit_email',
      ],

      VIEW: [
        'partner',
        'branchCountry',
        'name',
        'vat',
        'primaryContactEmail',
        'domain',
        'street',
        'houseNumber',
        'postalCode',
        'city',
        'email',
        'country',
        'invoice_email',
        'credit_email',
      ],
    },
    [VIEWS.PROJECTS]: { ADD: [], EDIT: [], DEACTIVATE: [] },
    [VIEWS.USERS]: { ADD: [], EDIT: [], DEACTIVATE: [] },
  },
  [ROLE.MANUFACTURER_ADMIN]: {
    [VIEWS.PARTNERS]: {
      EDIT: [
        'addressCountry',
        'street',
        'houseNumber',
        'city',
        'postalCode',
        'contactEmail',
        'francoLimit',
        'leadTime',
        'invoice_email',
        'credit_email',
        'receive_credit_emails',
      ],

      VIEW: [
        'name',
        'country',
        'vatNum',
        'domain',
        'addressCountry',
        'street',
        'houseNumber',
        'city',
        'postalCode',
        'contactEmail',
        'francoLimit',
        'leadTime',
        'invoice_email',
        'credit_email',
        'receive_credit_emails',
      ],
    },
    [VIEWS.PROJECTS]: { ADD: [], EDIT: [], DEACTIVATE: [] },
    [VIEWS.USERS]: { ADD: [], EDIT: [], DEACTIVATE: [] },
    [VIEWS.PRODUCTS]: { ADD: [], EDIT: [] },
  },
  [ROLE.MANUFACTURER]: {
    [VIEWS.PARTNERS]: {},
    [VIEWS.PROJECTS]: {},
    [VIEWS.USERS]: {},
    [VIEWS.PRODUCTS]: {},
  },
}

const {
  NEW_ITEM,
  IN_NEGOTIATION_BC_BLOCKED,
  IN_NEGOTIATION_MF_BLOCKED,
  IN_NEGOTIATION_BC_SENT,
  IN_NEGOTIATION_MF_SENT,
} = MATERIALSTATE
const {
  DISCOUNT,
  ADD_DELIVERIES,
  SEND_ERP,
  SET_DELIVERIES,
  FINALIZE_NOW,
  REQUEST,
  APPROVE,
  BUY_NOW,
  UPDATE,
  BC_APPROVE,
  REJECT,
  DELIVERY_COST,
  DELIVERY_SURCHARGES,
  MATERIAL_SURCHARGES,
} = BULK_ACTIONS

export const BULK_ACTION_PERMISSIONS = {
  [ROLE.CUBOTOO_ADMIN]: {
    [TABS.NEW_ITEMS]: [
      { action: BUY_NOW, allowedStates: [NEW_ITEM], label: 'FilterSection.BulkDeliveries.BuyNow' },
    ],
    [TABS.IN_NEGOTIATOIN]: [],
    [TABS.CREDITS]: ['credits'],
  },
  [ROLE.MANUFACTURER_ADMIN]: {
    [TABS.NEW_ITEMS]: [
      {
        action: FINALIZE_NOW,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.FinalizeAll',
      },
      {
        action: SEND_ERP,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.SendForErpAll',
      },
      {
        action: REQUEST,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.SendRequestAll',
      },
      {
        action: ADD_DELIVERIES,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.BulkAddDeliveries',
      },
      {
        action: SET_DELIVERIES,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.BulkSetDeliveries',
      },
      { action: DISCOUNT, allowedStates: [NEW_ITEM], label: 'FilterSection.BulkDiscount.Discount' },
      {
        action: DELIVERY_COST,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.ManufacturerActions.DeliveryCost',
      },
      {
        action: DELIVERY_SURCHARGES,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.ManufacturerActions.DeliverySurcharges',
      },
      {
        action: MATERIAL_SURCHARGES,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.ManufacturerActions.MaterialSurcharges',
      },
    ],
    [TABS.IN_NEGOTIATOIN]: [
      {
        action: FINALIZE_NOW,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED],
        label: 'FilterSection.BulkDeliveries.FinalizeAll',
      },
      {
        action: SEND_ERP,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED],
        label: 'FilterSection.BulkDeliveries.SendForErpAll',
      },
      {
        action: APPROVE,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED, IN_NEGOTIATION_BC_SENT],
        label: 'FilterSection.BulkDeliveries.SendBulkApproval',
      },
      {
        action: REJECT,
        allowedStates: [IN_NEGOTIATION_BC_SENT],
        label: 'FilterSection.BulkDeliveries.RejectAll',
      },
      {
        action: SET_DELIVERIES,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED],
        label: 'FilterSection.BulkDeliveries.BulkSetDeliveries',
      },
      {
        action: DISCOUNT,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED],
        label: 'FilterSection.BulkDiscount.Discount',
      },
      {
        action: DELIVERY_COST,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED],
        label: 'FilterSection.ManufacturerActions.DeliveryCost',
      },
      {
        action: DELIVERY_SURCHARGES,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED],
        label: 'FilterSection.ManufacturerActions.DeliverySurcharges',
      },
      {
        action: MATERIAL_SURCHARGES,
        allowedStates: [IN_NEGOTIATION_MF_BLOCKED],
        label: 'FilterSection.ManufacturerActions.MaterialSurcharges',
      },
    ],
    [TABS.CREDITS]: ['credits'],
  },
  [ROLE.BUILDING_CONTRACTOR_ADMIN]: {
    [TABS.NEW_ITEMS]: [
      { action: BUY_NOW, allowedStates: [NEW_ITEM], label: 'FilterSection.BulkDeliveries.BuyNow' },
      {
        action: REQUEST,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.SendRequestAll',
      },
      {
        action: ADD_DELIVERIES,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.BulkAddDeliveries',
      },
      {
        action: SET_DELIVERIES,
        allowedStates: [NEW_ITEM],
        label: 'FilterSection.BulkDeliveries.BulkSetDeliveries',
      },
    ],
    [TABS.IN_NEGOTIATOIN]: [
      {
        action: BC_APPROVE,
        allowedStates: [IN_NEGOTIATION_MF_SENT],
        label: 'FilterSection.BulkDeliveries.ApproveAll',
      },
      {
        action: REJECT,
        allowedStates: [IN_NEGOTIATION_MF_SENT],
        label: 'FilterSection.BulkDeliveries.RejectAll',
      },
      {
        action: UPDATE,
        allowedStates: [IN_NEGOTIATION_BC_BLOCKED],
        label: 'FilterSection.BulkDeliveries.RequestUpdateAll',
      },
      {
        action: SET_DELIVERIES,
        allowedStates: [IN_NEGOTIATION_BC_BLOCKED],
        label: 'FilterSection.BulkDeliveries.BulkSetDeliveries',
      },
    ],
  },
}

export const MF_ROLES = [ROLE.MANUFACTURER_ADMIN, ROLE.MANUFACTURER]
export const BC_ROLES = [ROLE.BUYER, ROLE.LEAD_BUYER, ROLE.FOREMAN, ROLE.BUILDING_CONTRACTOR_ADMIN]

export const project = {
  id: '',
}

export const excludeRoles = (excludedRoles) => {
  const rolesArray = Object.values(ROLE)

  const allowedPermissions = rolesArray.filter((role) => !excludedRoles.includes(role))
  return allowedPermissions
}

const defaultState = {
  sessionInfo: {},
  authStatus: 'Loading',
}

export const AuthContext = React.createContext(defaultState)

export const authEventEmitter = new EventEmitter()
//export const tokenExpiredEventEmitter = new EventEmitter()

export const AuthIsSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext)
  if (
    authStatus !== 'SignedIn' &&
    window.location.pathname !== '/' &&
    window.location.pathname !== '/login'
  ) {
    localStorage.setItem('redirectPath', `${window.location.pathname}${window.location.hash}`)
  }

  return <>{authStatus === 'SignedIn' ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }) => {
  const { authStatus } = useContext(AuthContext)

  return <>{authStatus === 'SignedOut' ? children : null}</>
}

export const AuthProvider = ({ children }) => {
  const [authStatus, setAuthStatus] = useState('Loading')
  const [sessionInfo, setSessionInfo] = useState({})
  const [attrInfo, setAttrInfo] = useState([])
  const [role, setRole] = useState('')
  const { i18n } = useTranslation()
  const [selectedProject, _setSelectedProject] = useLocalStorage('selected-project', { ...project })

  // * Leaving this code part comments, incase team wants to move from eventEmitter to using this context approach
  // useEffect(() => {
  //   // Note: Attaching the interceptor to httpV2 instead of adding it globally in httpV2 file
  //   // because in that case, authContext cannot be accessed
  //   const responseInterceptor = httpV2.interceptors.response.use(
  //     (response) => {
  //       return response
  //     },
  //     (error) => {
  //       if (error.response) {
  //         if (error.response.status === 401 || error.response.code == 'ERR_NETWORK') {
  //           signOut()
  //           window.localStorage.setItem('authStatus', 'SignedOut')
  //         }
  //       } else if (error.code === 'ERR_NETWORK') {
  //         // This is a case when no response is being sent by the backend API
  //         // Handle logout
  //         signOut()
  //         window.localStorage.setItem('authStatus', 'SignedOut')
  //       }
  //       return Promise.reject(error)
  //     },
  //   )

  //   return () => {
  //     httpV2.interceptors.response.eject(responseInterceptor)
  //   }
  // })

  useEffect(() => {
    getSessionInfo()
  }, [setAuthStatus, authStatus])

  useEffect(() => {
    const handleSignout = () => {
      signOut()
    }

    authEventEmitter.on('signout', handleSignout)
    return () => {
      authEventEmitter.off('signout', handleSignout)
    }
  }, [])

  async function getSessionInfo() {
    try {
      const session = await getSession()
      setSessionInfo({
        accessToken: session.accessToken.jwtToken,
        refreshToken: session.refreshToken.token,
        idToken: session.idToken.getJwtToken(),
      })
      const attr = await getAttributes()
      setAttrInfo(attr)
      const accessTokenDecoded = jwt(session.idToken.jwtToken)
      try {
        setRole(accessTokenDecoded['cognito:groups'][0].toString())
      } catch (err) {
        setRole('')
      }
      window.localStorage.setItem('authStatus', 'SignedIn')
      setAuthStatus('SignedIn')
    } catch (err) {
      window.localStorage.setItem('authStatus', 'SignedOut')
      setAuthStatus('SignedOut')
    }
  }

  if (authStatus === 'Loading') {
    return null
  }

  async function signInWithEmail(username, password) {
    try {
      const res = await cognito.signInWithEmail(username, password)
      if (res?.idToken) {
        window.localStorage.setItem('authStatus', 'SignedIn')
        const accessTokenDecoded = jwt(res.idToken.jwtToken)
        setRole(accessTokenDecoded['cognito:groups'][0].toString())
        setSessionInfo({
          accessToken: res?.accessToken.jwtToken,
          refreshToken: res?.refreshToken.token,
          idToken: res?.idToken.getJwtToken(),
        })
        setAuthStatus('SignedIn')
      }
      return res
    } catch (err) {
      window.localStorage.setItem('authStatus', 'SignedOut')
      setRole('')
      setSessionInfo({})
      setAuthStatus('SignedOut')
      throw err
    }
  }

  async function signUpWithEmail(given_name, family_name, email, password) {
    try {
      await cognito.signUpUserWithEmail(given_name, family_name, email, password)
    } catch (err) {
      throw err
    }
  }

  async function completeNewPasswordChallenge(username, password, newPassword) {
    try {
      const res = await cognito.signInWithEmail(username, password)
      if (res.callback === 'newPasswordRequired') {
        delete res.callback
        delete res.email
        delete res.email_verified
        await cognito.completeNewPasswordChallenge(newPassword, res)
      }
      window.localStorage.setItem('authStatus', 'SignedIn')
      setAuthStatus('SignedIn')
    } catch (err) {
      window.localStorage.setItem('authStatus', 'SignedOut')
      setAuthStatus('SignedOut')
      throw err
    }
  }

  const asyncLocalStorage = {
    async setItem(key, value) {
      await null
      return localStorage.setItem(key, value)
    },
    async getItem(key) {
      await null
      return localStorage.getItem(key)
    },
  }

  async function signOut() {
    cognito.signOut()
    window.localStorage.setItem('authStatus', 'SignedOut')
    setAuthStatus('SignedOut')
    setRole('')
    setSessionInfo({})
    window.localStorage.removeItem('accessToken')
    window.localStorage.removeItem('refreshToken')
    window.localStorage.removeItem('idToken')
    window.localStorage.removeItem('selected-branch')
    window.localStorage.removeItem('selected-project')
    const localStorageData = { ...window.localStorage }
    window.history.pushState(null, '', '/')
    for (const key in localStorageData) {
      asyncLocalStorage.setItem(key, localStorageData[key])
    }
  }

  async function verifyCode(username, code) {
    try {
      await cognito.verifyCode(username, code)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(username) {
    try {
      await cognito.sendCode(username)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(username) {
    try {
      await cognito.forgotPassword(username)
    } catch (err) {
      throw err
    }
  }

  async function confirmPassword(username, code, password) {
    try {
      await cognito.confirmPassword(username, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword, newPassword) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const isBC = () => {
    return (
      role === ROLE.BUYER ||
      role === ROLE.LEAD_BUYER ||
      role === ROLE.BUILDING_CONTRACTOR_ADMIN ||
      role === ROLE.FOREMAN
    )
  }

  const setSelectedProject = (project) => {
    _setSelectedProject(project)
  }

  const state = {
    authStatus,
    sessionInfo,
    attrInfo,
    role,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    getSessionInfo,
    sendCode,
    forgotPassword,
    confirmPassword,
    changePassword,
    completeNewPasswordChallenge,
    getAttributes,
    setAttribute,
    isBC,
    setSelectedProject,
    selectedProject,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
